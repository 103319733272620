import axios from '@axios'
export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchWarehouseData(ctx,queryParams){
            var data = JSON.stringify(queryParams);
              
            return new Promise((resolve, reject) => {
              axios
                .post('/Warehouse/v1/getall',data)
                .then(response => resolve(response))
                .catch(error => {reject(error)})
            })
        },
        async addWarehouseData(ctx,queryParams){
            var data = queryParams;

            return new Promise((resolve, reject) => {
                axios
                  .post('/Warehouse/v1/add',data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async updateWarehouseData(ctx,queryParams){
            var data = queryParams;
            console.log(data)
            return new Promise((resolve, reject) => {
                axios
                  .put(`/Warehouse/v1/update/${data.id}`,data)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
        async deleteWarehouseData(ctx,queryParams){
 
            return new Promise((resolve, reject) => {
                axios
                  .delete('/Warehouse/v1/delete/'+queryParams.id)
                  .then(response => resolve(response))
                  .catch(error => reject(error))
              })

        },
    }
}